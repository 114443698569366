
import { defineComponent } from "vue"

export default defineComponent({
  name: "Edit Goal Modal",
  props: {
    modalId: String,
    modalHeading: String
  }
});
