import _ from "lodash"
import Swal from "sweetalert2/dist/sweetalert2.js"

export async function displayStatus(value) {
  const good = Object.keys(_.pickBy(value, (item) => item.status_check)).join(", ")
  const bad = Object.keys(_.pickBy(value, (item) => !item.status_check)).join(", ")

  let resp

  if (good.length > 0 && bad.length > 0) {
    resp = `Successfully added ${good} but ${bad} failed`
  } else if (good.length > 0) {
    resp = `Successfully added ${good} connection`
  } else {
    resp = `Couldn't add ${bad} connection`
  }

  await Swal.fire({
    text: resp,
    icon: bad.length > 0 ? "error" : "success",
    confirmButtonText: "Ok",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn btn-light-primary"
    }
  })
}
