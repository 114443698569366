
import { defineComponent, ref } from "vue"
import { ErrorMessage, Field, Form } from "vee-validate"
import * as Yup from "yup"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import { Modal } from "bootstrap"
import { mapGetters } from "vuex"
import { displayStatus } from "@/core/helpers/displayStatus"

export default defineComponent({
  name: "CreateLocation",
  props: {
    closeModalId: String,
    accountNum: Number
  },
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const submitAddLocationRequest = ref<HTMLElement | null>(null)

    const addLocation = ref<any>({
      gmb_location_name: "",
      google_maps_embed_code: "",
      google_place_id: "",
      name: "",
      review_link: "",
      status: "active",
      gmb_email: "",
      gmb_account: "",
      yext_entity_id: ""
    })

    const addLocationFormValidator = Yup.object().shape({
      name: Yup.string().required().label("City, ST"),
      google_place_id: Yup.string().required().label("Google Place ID"),
      google_maps_embed_code: Yup.string().label("Maps Embed"),
      yext_entity_id: Yup.string().label("Yext Entity ID"),
      gmb_location_name: Yup.string().matches(/(locations)\/\d+/, {
        message: "should match pattern 'locations/123456789'",
        excludeEmptyString: true
      }).required().label("locations/NUMBER"),
      gmb_account: Yup.string().matches(/(accounts)\/\d+/, {
        message: "should match pattern 'accounts/123456789'",
        excludeEmptyString: true
      }).required().label("accounts/NUMBER"),
      gmb_email: Yup.string().email().required().label("GMB Email"),
      review_link: Yup.string().label("https://search.google.com/local/writereview?placeid="),
    })

    return {
      addLocationFormValidator,
      submitAddLocationRequest,
      addLocation,
    }
  },
  computed: {
    ...mapGetters(["activeAccountInfo"]),
  },
  async created() {
    this.addLocation.gmb_account = this.activeAccountInfo.relationships.locations.slice(-1)[0].gmb_account ?? ""
    this.addLocation.gmb_email = this.activeAccountInfo.relationships.locations.slice(-1)[0].gmb_email ?? ""
  },
  methods: {
    async sendRequest() {
      ApiService.setHeader()

      let addLocationResponse
      try {
        addLocationResponse = await ApiService.post("/accounts/" + this.$props.accountNum + "/locations", { ...this.addLocation })
      } catch (e) {
        console.error("Problem fetching add location response", e)
        return []
      }

      if (addLocationResponse.data) {
        return addLocationResponse.data
      } else {
        return []
      }
    },
    async saveLocation() {
      if (this.submitAddLocationRequest) {
        // Activate indicator
        this.submitAddLocationRequest.setAttribute("data-kt-indicator", "on")

        const response = await this.sendRequest()

        if (response.data.id !== undefined) {
          if (this.$props.closeModalId) {
            const modalElement = document.getElementById(this.$props.closeModalId)
            const modalInstance = Modal.getInstance(modalElement)
            modalInstance.hide()
            // Hacky fix for bootstrap modal's backdrop persisting
            document.querySelector(".modal-backdrop")?.remove()
          }
          await Swal.fire({
            text: response.data.attributes.name + " location has been created successfully!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          }).then(() => {
            document.querySelector("body")?.removeAttribute("style")
            document.getElementById(response.data.id)?.scrollIntoView()
            document.getElementById(response.data.id)?.classList.add("highlight")
          })

          this.submitAddLocationRequest.removeAttribute("data-kt-indicator")
          this.$emit("refreshLocations")

          await displayStatus(response.meta.status_check)

        } else {
          Swal.fire({
            text: "Error adding new location",
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitAddLocationRequest.removeAttribute("data-kt-indicator")

        }
      }
    },
  }
});
