
import { defineComponent, ref } from "vue"
import UpdateLocation from "@/components/UpdateLocation.vue"
import CreateLocation from "@/components/CreateLocation.vue"
import ModalWrapper from "@/components/ModalWrapper.vue"
import LocationService from "@/core/services/LocationService"
import { mapGetters } from "vuex"
import _ from "lodash"
import Swal from "sweetalert2/dist/sweetalert2.js"
import { Actions } from "@/store/enums/StoreEnums"
import store from "@/store"

export default defineComponent({
  name: "Locations",
  components: {
    UpdateLocation,
    CreateLocation,
    ModalWrapper
  },
  async created() {
    this.locations = await new LocationService().getLocations(this.activeAccountInfo.id)
    this.accountNum = this.activeAccountInfo.id
    this.primaryLocationId = this.activeAccountInfo.details.primary_location_id

    if (this.locations) {
      this.sortLocations()
    }
  },
  computed: {
    ...mapGetters(["activeAccountInfo"]),
  },
  methods: {
    async refreshLocations() {
      this.loading = true

      // get updated primary_location_id
      await store.dispatch(Actions.UPDATE_ACTIVE_ACCOUNT_INFO, this.activeAccountInfo.id)

      this.locations = [] as Location[]
      this.locations = await new LocationService().getLocations(this.activeAccountInfo.id)

      if (this.locations) {
        this.primaryLocationId = this.activeAccountInfo.details.primary_location_id
        this.sortLocations()
      }

      this.primaryLocationUpdate = ""
      this.loading = false
    },
    sortLocations() {
      this.sortedLocations = {}
      this.sortedLocations = _.groupBy(this.locations, (o) => o["attributes"].status.toLowerCase())
    },
    setAsPrimary(locationId) {
      this.primaryLocationUpdateError = false

      if (this.primaryLocationUpdate == locationId) {
        this.primaryLocationUpdate = ""
      } else if (this.primaryLocationUpdate == "") {
        this.primaryLocationUpdate = locationId
      } else if (this.primaryLocationUpdate != locationId && this.primaryLocationUpdate != "") {
        // throw error
        Swal.fire({
          text: "Only one location can be primary, please save location",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })

        this.primaryLocationUpdateError = true
        this.primaryLocationUpdateErrorId = locationId
      }

    }
  },
  data() {

    let loading = ref<boolean>(false)
    let primaryLocationUpdate = ''
    let primaryLocationUpdateErrorId = ''
    let primaryLocationUpdateError = ref(false).value

    return {
      loading,
      primaryLocationUpdateError,
      primaryLocationUpdateErrorId,
      primaryLocationUpdate,
      primaryLocationId: null,
      accountNum: 0,
      locations: [] as Location[],
      sortedLocations: {}
    }
  }
});
