
import { defineComponent, ref } from "vue"
import { ErrorMessage, Field, Form } from "vee-validate"
import * as Yup from "yup"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import { Location } from "@/core/interfaces/Location"
import store from "@/store"
import _ from "lodash"
import { displayStatus } from "@/core/helpers/displayStatus"

export default defineComponent({
  name: "UpdateLocation",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: {
    loc: {
      type: Object as () => Location,
      required: true
    },
    primary: Boolean,
    setPrimaryError: Boolean
  },
  emits: ["setAsPrimary", "refreshLocations"],
  setup(props) {
    const submitUpdateLocationRequest = ref<HTMLElement | null>(null)
    let updateLocation = ref<any>({
      name: props.loc.attributes["name"],
      google_place_id: props.loc.attributes["google_place_id"],
      gmb_location_name: props.loc.attributes["gmb_location_name"],
      google_maps_embed_code: props.loc.attributes["google_maps_embed_code"],
      yext_entity_id: props.loc.attributes["yext_entity_id"],
      review_link: props.loc.attributes["review_link"],
      status: props.loc.attributes["status"].toLowerCase(),
      gmb_email: props.loc.attributes["gmb_email"],
      gmb_account: props.loc.attributes["gmb_account"],
      setPrimary: false
    })

    const updateLocationFormValidator = Yup.object().shape({
      name: Yup.string().required().label("City, ST"),
      google_place_id: Yup.string().required().label("Google Place ID"),
      gmb_location_name: Yup.string().matches(/(locations)\/\d+/, {
        message: "should match pattern 'locations/123456789'",
        excludeEmptyString: true
      }).required().label("locations/NUMBER"),
      gmb_account: Yup.string().matches(/(accounts)\/\d+/, {
        message: "should match pattern 'accounts/123456789'",
        excludeEmptyString: true
      }).required().label("accounts/NUMBER"),
      gmb_email: Yup.string().email().required().label("GMB Email"),
      google_maps_embed_code: Yup.string().label("Maps Embed Code"),
      yext_id: Yup.string().label("Yext Entity ID"),
      review_link: Yup.string().label("https://search.google.com/local/writereview?placeid="),
      status: Yup.string().label("Status"),
    })

    return {
      updateLocationFormValidator,
      submitUpdateLocationRequest,
      updateLocation
    }
  },
  data(props) {
    let error = ref(props.setPrimaryError).value

    return {
      error
    }
  },
  methods: {
    setAsPrimary() {
      this.$emit("setAsPrimary", this.$props.loc.id)
    },
    async updateAccountPrimaryLocation(id) {

      const accountNum = store.getters.activeAccountInfo.id

      ApiService.setHeader()

      let resp
      try {
        resp = await ApiService.put(`/accounts/${accountNum}`, { data: { primary_location_id: id } })
      } catch (e) {
        console.error("Problem setting primary location", e)
      }

      if (resp.status == 200) {
        return { status: 200 }
      }

      return { status: 500 }


    },
    async sendRequest() {
      ApiService.setHeader()
      let attrs = { ...this.updateLocation }

      let updateLocationResponse, setPrimary

      if (attrs.setPrimary) {
        setPrimary = await this.updateAccountPrimaryLocation(this.$props.loc.id)
      }

      try {
        attrs = _.omit(attrs, ["setPrimary"])
        updateLocationResponse = await ApiService.put("/locations/" + this.$props.loc.id, { ...attrs })
      } catch (e) {
        console.error("Problem fetching update location response", e)
      }

      if (updateLocationResponse.data && setPrimary && setPrimary.status == 200) {
        updateLocationResponse.data.data.updatePrimary = true
        return updateLocationResponse.data
      } else if (updateLocationResponse.data) {
        updateLocationResponse.data.data.updatePrimary = false
        return updateLocationResponse.data
      } else {
        console.log("No data returned from update location response")
        return false
      }
    },
    async saveLocation() {
      if (this.submitUpdateLocationRequest) {
        // Activate indicator
        this.submitUpdateLocationRequest.setAttribute("data-kt-indicator", "on")

        const response = await this.sendRequest()

        this.updateLocation.setPrimary = false

        if (response.data.id !== undefined) {
          let resp = `Updated the ${response.data.attributes.name} location.`
          resp += response.data.updatePrimary ? " And made primary location." : ""
          await Swal.fire({
            text: resp,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitUpdateLocationRequest.removeAttribute("data-kt-indicator")
          this.$emit("refreshLocations")
          await displayStatus(response.meta.status_check)
        } else {
          await Swal.fire({
            text: "Error updating location",
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitUpdateLocationRequest.removeAttribute("data-kt-indicator")
        }
      }
    },
  },
  watch: {
    setPrimaryError: {
      handler(val) {
        if (val) {
          this.updateLocation.setPrimary = false
        }
      }
    }
  }
});
