import ApiService from "@/core/services/ApiService"

export default class LocationService {
  async getLocations(id: string) {
    ApiService.setHeader()

    let locations
    try {
      locations = await ApiService.get("/accounts/" + id + "/locations")
    } catch (e) {
      console.error("Problem fetching Locations", e)
      return []
    }

    if (locations.data) {
      return locations.data.data.sort((a, b) => {
        return a.attributes.name.toLowerCase() < b.attributes.name.toLowerCase() ? -1 : 1
      })
    } else {
      return []
    }
  }

  async getLocation(id: string) {
    ApiService.setHeader()

    let loc
    try {
      loc = await ApiService.query("/locations/" + id, {})
    } catch (e) {
      console.error("Problem fetching location", e)
      return []
    }

    return loc.data || []
  }
}
